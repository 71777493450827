@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* #009739 */
    --color-z-green: rgb(0, 151, 57);
    /* #FCE300 */
    --color-z-yellow: rgb(252, 227, 0);
    /* #000000 */
    --color-z-black: rgb(0, 0, 0);
    /* #FFFFFF */
    --color-z-white: rgb(255, 255, 255);
    /* #EF3340 */
    --color-z-red: rgb(239, 51, 64);
  }

  html,
  body {
    overflow: hidden;
    font-family: "Edu NSW ACT Foundation", system-ui, sans-serif;
    font-size: 1.2rem;
  }
}

body {
  position: relative;
}

#page-loader-overlay {
  position: fixed;
  z-index: 99999;
  inset: 0;
  background: var(--color-z-green);
  transition: 1s linear;
  animation: page-loader-overlay 1s linear;
}

#page-loader-overlay.hide {
  visibility: hidden;
  opacity: 0;
  /* animation: page-loader-overlay 1s linear; */
}

@keyframes page-loader-overlay {
  14% {
    background: var(--color-z-green);
  }

  29% {
    background: var(--color-z-yellow);
  }

  43% {
    background: var(--color-z-red);
  }

  57% {
    background: var(--color-z-black);
  }

  71% {
    background: var(--color-z-red);
  }

  86% {
    background: var(--color-z-yellow);
  }

  100% {
    background: var(--color-z-green);
  }
}

.page-screen {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

#opener-inner {
  position: relative;
  flex-grow: 1;
  padding: 1rem;
  background-color: white;
  color: #000;
  border-radius: 0.5rem;
  overflow-y: auto;
}

#navbar {
  position: sticky;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.5rem;
  text-align: center;
  overflow: hidden;
  z-index: 99999;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  & a {
    font-size: 18px;

    @media (min-width: 768px) {
      font-size: 22px;
    }
  }
}

#raining-images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
}

#main-body {
  position: relative;
  padding-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow-x: hidden;
  z-index: 99998;
}

#meme-gallery {
  & img {
    /* h-32 md:h-48 w-auto */
    height: 8rem;
    width: auto;

    @media (min-width: 768px) {
      height: 12rem;
    }
  }
}